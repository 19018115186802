import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconBrands from '@fortawesome/free-brands-svg-icons'
import { useGlitch } from 'react-powerglitch'
import Particles from "@tsparticles/react";

const Home = (props: any) => {
    const controller = useController();
    const glitch = useGlitch({
        timing: {
            duration: 6000,
        },
        glitchTimeSpan: {
            start: 0.1,
            end: 0.2,
        },
        slice: {
            count: 10,
        }
    });

    return (
        <S.mainContainer>
            <S.mainWithLogo>
                <S.imageLogo ref={glitch.ref}>
                    <S.imgLogoYellowPart alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_glow_only.png" />
                    <S.imgLogoDraw alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_draw_only.png" />
                </S.imageLogo>
                <S.titles>
                    <S.title ref={glitch.ref}>{'Arezdot'.toUpperCase()}</S.title>
                </S.titles>
            </S.mainWithLogo>
            <S.servicesArea>
                {controller.services.map((item, index) => {
                    return (
                        <S.serviceBit href={item.sendTo} key={'service_' + index} expanded={item.expanded} onClick={() => { controller.toggleService(item.id) }}>
                            {/* @ts-ignore */}
                            <S.serviceIcon icon={icons[item.icon]} />
                            <S.serviceText>
                                <S.serviceTitle>{item.title}</S.serviceTitle>
                                <S.serviceDescription>{item.description} <b>(Clique para saber mais)</b></S.serviceDescription>
                            </S.serviceText>
                        </S.serviceBit>
                    )
                })}
            </S.servicesArea>
            <Particles
                id="tsparticles"
                particlesLoaded={controller.particlesLoaded as any}
                options={controller.options}
            />
        </S.mainContainer>
    )
}

export default Home;