import general from '../../utils/general';
import axios from 'axios';
import { plan } from './types'
import { useEffect, useState, useMemo } from 'react';

export const useController = () => {
    const [init, setInit] = useState(false);
    const [plans, setPlans] = useState<plan[]>([]);
    const [planShowing, setPlanShowing] = useState(1);
    const [planPeriod, setPlanPeriod] = useState(2);
    const [functionalities, setFunctionalities] = useState([
        {
            icon: 'faChartPie',
            title: 'Visualize o Sucesso da Sua Empresa em Um Só Lugar',
            /* description: 'Tenha uma visão global do desempenho da sua empresa. Com gráficos e dados precisos, você pode acompanhar KPIs críticos, medir o progresso em relação às metas e identificar áreas de melhoria rapidamente. Seja para monitorar vendas, finanças ou desempenho de equipe, o Arezdot ERP é sua central de informações para decisões estratégicas.' */
            description: 'Obtenha uma visão abrangente e completa do desempenho da sua empresa com o Arezdot ERP. Por meio de gráficos intuitivos e dados detalhados em tempo real, você pode acompanhar os KPIs mais relevantes, monitorar o progresso em direção aos seus objetivos estratégicos e identificar rapidamente oportunidades de melhoria. Independentemente de estar analisando vendas, controlando o fluxo financeiro ou avaliando a produtividade da equipe, o Arezdot ERP centraliza todas as informações essenciais, permitindo decisões estratégicas embasadas, rápidas e precisas. Transforme dados em insights valiosos para impulsionar o crescimento sustentável da sua empresa.'
        },
        {
            icon: 'faLayerGroup',
            title: 'Organização Intuitiva para Melhor Gestão',
            /* description: 'Mantenha seus produtos e serviços organizados de forma inteligente. Isso permite que você segmente e gerencie inventários de forma mais eficiente, facilitando a navegação e a análise de dados. Com isso, a sua equipe ganha mais agilidade ao realizar operações e você encontra o que seus clientes precisam de forma muito mais rápida.' */
            description: 'Organize seus produtos e serviços de maneira inteligente e eficiente. Com uma estruturação estratégica, você pode segmentar e gerenciar seus inventários com precisão, facilitando a navegação e a análise de dados essenciais. Isso resulta em uma gestão mais ágil e simplificada, permitindo que sua equipe execute operações com maior rapidez e eficiência. Além disso, a organização otimizada garante que você localize rapidamente os itens ou serviços que seus clientes demandam, proporcionando uma experiência mais ágil e satisfatória. Maximize a produtividade e otimize o atendimento ao cliente com uma gestão inteligente de seus recursos.'
        },
        {
            icon: 'faBoxesStacked',
            title: 'Controle Absoluto de Suprimentos e Inventário',
            /* description: 'Nunca mais sofra com falta de produtos ou excesso de estoque. O módulo de Estoque oferece um controle detalhado do inventário, permitindo que você acompanhe a entrada e saída de produtos, defina níveis mínimos e máximos, e acompanhe de uma forma rápida e fácil como seu estoque está sendo movimentado. Isso resulta em economia de custos e maior eficiência operacional.' */
            description: 'Elimine de vez os problemas com falta de produtos ou excesso de estoque. O módulo de Estoque oferece um controle avançado e detalhado do seu inventário, permitindo monitorar de forma precisa a entrada e saída de mercadorias. Defina níveis mínimos e máximos para cada item, garantindo que você nunca fique sem produtos essenciais, ao mesmo tempo em que evita o acúmulo desnecessário. Com uma interface simples e intuitiva, você acompanha o fluxo do seu estoque em tempo real, otimizando suas decisões. O resultado? Redução de custos, maior eficiência operacional e uma gestão de estoque muito mais inteligente e ágil.'
        },
        {
            icon: 'faScrewdriverWrench',
            title: 'Controle e Gestão Simplificados de Serviços',
            /* description: 'O módulo de Serviços permite que você cadastre e gerencie os serviços oferecidos pela sua empresa. Com ele, você pode acompanhar a quantidade de vezes que cada serviço foi vendido, o que ajuda a identificar quais são os mais populares e lucrativos.' */
            description: 'O módulo de Serviços oferece uma solução completa para cadastrar e gerenciar os serviços oferecidos pela sua empresa de forma organizada e eficiente. Através dele, você pode monitorar a frequência de vendas de cada serviço, obtendo insights valiosos sobre sua popularidade e rentabilidade. Com esses dados em mãos, fica mais fácil identificar quais serviços estão gerando maior demanda e quais são os mais lucrativos, permitindo otimizar suas estratégias e oferecer um portfólio alinhado com as necessidades do mercado. Ganhe controle total sobre os serviços prestados e potencialize os resultados do seu negócio.'
        },
        {
            icon: 'faDollar',
            title: 'Controle Financeiro Completo e Eficiente',
            /* description: 'O módulo de Finanças coloca você no controle total das finanças da sua empresa. Gerencie contas a pagar e a receber, acompanhe o fluxo de caixa, e fique sempre a frente de suas finanças. Evite erros, reduza custos e melhore a lucratividade da empresa.' */
            description: 'O módulo de Finanças coloca você no comando absoluto das finanças da sua empresa. Com ele, você pode gerenciar contas a pagar e a receber de forma integrada, acompanhar o fluxo de caixa em tempo real e garantir uma visão clara e precisa da saúde financeira do seu negócio. Mantenha-se sempre um passo à frente, evitando erros comuns, automatizando processos, e otimizando o controle de despesas. Isso não apenas reduz custos, mas também melhora a lucratividade e a eficiência financeira da sua empresa, proporcionando uma gestão mais estratégica e sustentável.'
        },
        {
            icon: 'faShapes',
            title: 'Ferramentas Complementares para uma Gestão Simplificada',
            /* description: 'Aumente a produtividade com as utilidades integradas do ERP. Este módulo oferece um conjunto de ferramentas práticas que simplificam a execução de tarefas, como verificações de CNPJ, conversor de moedas atualizados e muito mais. Uma funcionalidade indispensável para quem busca eficiência.' */
            description: 'Eleve a produtividade da sua empresa com as utilidades integradas do ERP. Este módulo disponibiliza uma série de ferramentas essenciais que facilitam a execução de tarefas cotidianas, como consultas rápidas de CNPJ, conversão de moedas com taxas atualizadas em tempo real, e muito mais. Essas funcionalidades otimizam o tempo da sua equipe, proporcionando agilidade nas operações e maior precisão nas informações. Ideal para empresas que buscam eficiência máxima e desejam automatizar processos, este módulo se torna indispensável para quem valoriza praticidade e resultados rápidos.'
        },
        {
            icon: 'faTruckField',
            title: 'Relacionamento e Gestão Simplificados dos seus fornecedores',
            /* description: 'Centralize todas as informações dos seus fornecedores e mantenha um relacionamento estratégico com cada um. Este módulo permite que você gerencie o relacionamento com seus fornecedores, garantindo sempre a qualidade dos produtos e serviços adquiridos pela sua empresa.' */
            description: 'Centralize todas as informações dos seus fornecedores em um único lugar e construa um relacionamento estratégico com cada um deles. Com este módulo, você pode gerenciar de forma eficaz o relacionamento com seus fornecedores, acompanhando desde o histórico de transações até a qualidade dos produtos e serviços fornecidos. Isso permite que sua empresa mantenha padrões elevados de qualidade, negociando de forma mais assertiva e garantindo parcerias sólidas e produtivas. Com um controle aprimorado, você otimiza o processo de compras, minimiza riscos e fortalece a cadeia de suprimentos, assegurando o sucesso das suas operações.'
        },
        {
            icon: 'faUserGroup',
            title: 'Potencialize o Desempenho do Seu Time',
            /* description: 'A gestão de pessoas é essencial para o sucesso do seu negócio. O módulo de Recursos Humanos facilita o desenvolvimento de equipes. Foque no que importa: o crescimento e a satisfação do seu time.' */
            description: 'A gestão de pessoas é um pilar fundamental para o sucesso de qualquer negócio. Com o módulo de Recursos Humanos, você simplifica o desenvolvimento e a administração das suas equipes. Ele oferece ferramentas que otimizam processos como recrutamento, treinamento, acompanhamento de desempenho e gestão de benefícios, permitindo que você se concentre no que realmente importa: o crescimento profissional e a satisfação do seu time. Ao investir no bem-estar e na capacitação dos colaboradores, você cria um ambiente de trabalho mais produtivo e motivado, contribuindo diretamente para o sucesso e a sustentabilidade da sua empresa.'
        },
        {
            icon: 'faShieldHalved',
            title: 'Segurança e Controle de Acesso',
            /* description: 'Proteja seus dados e garanta que apenas pessoas autorizadas tenham acesso ao sistema. Com o módulo de Segurança, você pode definir níveis de permissão, quem pode acessar o que, restringir o acesso a informações sensíveis e monitorar as atividades dos usuários. Isso ajuda a manter a integridade dos dados e a segurança da sua empresa.' */
            description: 'Proteja seus dados de forma robusta e garanta que apenas usuários autorizados tenham acesso ao sistema. O módulo de Segurança permite que você configure níveis de permissão detalhados, controlando exatamente quem pode acessar cada área do sistema e restringindo o acesso a informações sensíveis. Com essa proteção reforçada, você assegura a integridade dos dados e minimiza riscos, proporcionando maior segurança para a sua empresa e tranquilidade para sua equipe.'
        },
        {
            icon: 'faUsers',
            title: 'Colaboração Efetiva e Alinhamento Organizacional',
            /* description: 'Crie equipes, delegue metas e monitore o desempenho em tempo real para garantir que os objetivos sejam alcançados de forma eficaz.' */
            description: 'Forme equipes estratégicas, delegue metas claras e monitore o desempenho em tempo real para garantir que os objetivos sejam atingidos de maneira eficiente e coordenada. Com essa abordagem, você pode acompanhar o progresso de cada equipe, identificar obstáculos rapidamente e ajustar o curso das ações conforme necessário. Ao fornecer feedback contínuo e mensurável, você mantém todos alinhados com os objetivos da empresa, incentivando uma cultura de alta performance e garantindo que cada meta seja cumprida com excelência.'
        },
        {
            icon: 'faUser',
            title: 'Relacionamento Inteligente para Fidelização',
            /* description: 'Conheça seus clientes e crie relacionamentos duradouros. Com o módulo de Clientes, você pode registrar informações importantes sobre cada cliente, como histórico de compras, preferências e feedbacks. Isso ajuda a personalizar o atendimento, aumentar a fidelização e melhorar a experiência do cliente com a sua empresa.' */
            description: 'Conheça profundamente seus clientes e construa relacionamentos duradouros. O módulo de Clientes permite registrar e gerenciar informações essenciais sobre cada cliente, como histórico de compras, preferências e feedbacks. Com esses dados em mãos, você pode personalizar o atendimento, oferecer soluções sob medida e antecipar as necessidades dos seus clientes, aumentando significativamente a satisfação e a fidelização. Além disso, ao entender melhor o comportamento de seus consumidores, você aprimora a experiência deles com sua empresa, criando conexões mais fortes e garantindo um relacionamento de longo prazo baseado em confiança e valor agregado.'
        },
        {
            icon: 'faFilterCircleDollar',
            title: 'Conquiste Novos Clientes de Forma Estratégica',
            /* description: 'Converta leads em clientes com o acompanhamento estratégico deste módulo. Registre, categorize e gerencie seus leads em um só lugar, otimizando o funil de vendas. Identifique oportunidades, acompanhe o progresso e feche negócios com mais eficiência.' */
            description: 'Converta leads em clientes de forma estratégica e eficiente com o módulo de Gestão de Leads. Centralize o registro, categorização e gerenciamento de todos os seus leads em um único lugar, otimizando cada etapa do funil de vendas. Com essa visão organizada, você consegue identificar oportunidades valiosas, acompanhar o progresso de cada lead em tempo real e ajustar suas abordagens para maximizar as chances de conversão. Aumente a eficiência da sua equipe de vendas e feche negócios de maneira mais rápida e assertiva, transformando potenciais clientes em parceiros de longo prazo.'
        },
        {
            icon: 'faRobot',
            title: 'Inteligência Artificial: Transforme Dados em Decisões Poderosas',
            /* description: 'A inteligência artificial é uma ferramenta poderosa para transformar dados em decisões estratégicas. Com este módulo, você pode criar prompts personalizados para orientar ações, identificar oportunidades e melhorar a eficiência operacional. Aproveite o poder da IA para impulsionar o crescimento da sua empresa.' */
            description: 'A inteligência artificial é uma aliada poderosa para transformar dados em decisões estratégicas de alto impacto. Com este módulo, você pode criar prompts personalizados que direcionam ações precisas, identificam oportunidades escondidas e melhoram significativamente a eficiência operacional. Ao aproveitar o poder da IA, sua empresa pode otimizar processos, prever tendências de mercado e automatizar tarefas críticas, permitindo uma tomada de decisões mais rápida e embasada. Essa tecnologia impulsiona o crescimento sustentável, proporcionando uma vantagem competitiva que eleva o desempenho e a inovação em todos os níveis do negócio.'
        },
        {
            icon: 'faPenToSquare',
            title: 'Capture Dados de Forma Fácil e Eficiente',
            /* description: 'Crie e gerencie formulários personalizados para capturar informações valiosas de clientes, leads e funcionários. Este módulo facilita a criação de pesquisas, feedbacks e formulários de solicitação, permitindo que sua empresa colete dados essenciais de forma prática e organizada.' */
            description: 'Crie e gerencie formulários personalizados para capturar informações valiosas de clientes, leads e funcionários. Este módulo facilita a criação de pesquisas, feedbacks e formulários de solicitação, permitindo que sua empresa colete dados essenciais de forma prática e organizada. Com uma interface intuitiva, você centraliza todas as informações em um só lugar, melhorando a gestão e a análise de dados importantes para o sucesso do seu negócio. Além das pesquisas e formulários tradicionais, este módulo oferece a opção de criar formulários de agendamento personalizados. Com eles, você pode organizar compromissos, reuniões ou reservas de forma automatizada, otimizando a experiência dos seus clientes e a produtividade da sua equipe. Simplifique o processo de agendamento e garanta que todos os eventos estejam bem estruturados e sincronizados.'
        },
        {
            icon: 'faCalendar',
            title: 'Organização e Planejamento em Dia',
            /* description: 'Acompanhe compromissos, reuniões e prazos importantes com o módulo de Agenda. Você pode sincronizar eventos e tarefas, garantindo que tudo esteja devidamente organizado e planejado.' */
            description: 'Gerencie compromissos, reuniões e prazos críticos com eficiência utilizando o módulo de Agenda. Este recurso permite que você sincronize eventos e tarefas em um só lugar, garantindo que tudo esteja devidamente organizado e planejado. Com a facilidade de visualização e atualização em tempo real, sua equipe permanece alinhada, evitando conflitos de horário e garantindo o cumprimento de prazos. Mantenha o controle sobre todos os compromissos importantes e otimize o planejamento diário para uma gestão mais fluida e produtiva.'
        },
        {
            icon: 'faBarsProgress',
            title: 'Acompanhe e Alcance Seus Objetivos',
            /* description: 'Defina, monitore e alcance as metas estratégicas da sua empresa com este módulo, com ele você pode acompanhar o progresso em tempo real, ajustando ações conforme necessário para garantir que sua equipe esteja sempre no caminho certo para atingir seus objetivos.' */
            description: 'Estabeleça, monitore e conquiste as metas estratégicas da sua empresa com eficiência por meio do módulo de Metas. Com ele, você obtém uma visão em tempo real do progresso de cada meta, permitindo um acompanhamento contínuo e detalhado. Essa funcionalidade oferece a flexibilidade de ajustar ações rapidamente conforme necessário, assegurando que sua equipe permaneça alinhada com os objetivos traçados e possa reagir prontamente a desafios ou mudanças. Com essa ferramenta, você garante que o desempenho de sua empresa seja otimizado, mantendo o foco no crescimento e no sucesso sustentável.'
        },
        {
            icon: 'faHouseCircleCheck',
            title: 'Controle de Ativos Empresariais',
            /* description: 'Gerencie os bens da sua empresa de forma simples e eficiente. Com o módulo de Patrimônio, você pode registrar, rastrear e monitorar todos os ativos da sua empresa, garantindo que estejam sempre em bom estado e disponíveis para uso. Isso ajuda a reduzir custos, evitar perdas e otimizar o uso de recursos.' */
            description: 'Gerencie os ativos da sua empresa com praticidade e eficiência. O módulo de Patrimônio permite que você registre, rastreie e monitore todos os bens da organização, garantindo que eles estejam sempre em bom estado e prontos para uso. Com essa gestão centralizada, você reduz custos, evita perdas e maximiza o aproveitamento dos recursos disponíveis. Mantenha total controle sobre seu patrimônio e assegure um uso inteligente dos seus ativos. Cuidar dos seus bens é investir no futuro da sua empresa.'
        },
        {
            icon: 'faCar',
            title: 'Gerenciamento Total da Frota',
            /* description: 'Se sua empresa possui uma frota de veículos, este módulo é essencial. Gerencie o histórico de manutenção, despesas e alocação de veículos, tudo em um só lugar. Isso ajuda a reduzir custos operacionais e garantir que sua frota esteja sempre em perfeito funcionamento.' */
            description: 'Se sua empresa possui uma frota de veículos, este módulo é indispensável. Ele permite gerenciar o histórico completo de manutenção, monitorar despesas e organizar a alocação de veículos de forma centralizada e eficiente. Com todas as informações reunidas em um único lugar, você otimiza a gestão da frota, reduz custos operacionais, evita eventos inesperados e garante que os veículos estejam sempre em perfeito funcionamento. Mantenha sua frota rodando de forma segura e econômica, maximizando o desempenho e a longevidade dos seus veículos.'
        },
        {
            icon: 'faBuilding',
            title: 'Gestão Multinível com Facilidade',
            /* description: 'Gerencie múltiplas empresas ou filiais sem complicação. É possível alternar entre diferentes CNPJs, oferecendo um controle centralizado, mas flexível, para você que possui mais de uma empresa ou filial. (Cada empresa é um plano diferente).' */
            description: 'Gerencie múltiplas empresas ou filiais com total simplicidade e eficiência. Com este módulo, você pode alternar facilmente entre diferentes empresas, permitindo um controle centralizado, mas flexível, para quem administra mais de uma empresa ou filial. Cada empresa opera com um plano distinto, garantindo uma gestão individualizada e organizada. Essa funcionalidade oferece a você total visibilidade e controle sobre todas as operações, facilitando a tomada de decisões estratégicas e o monitoramento de resultados em diversas frentes, sem complicação.'
        },
        {
            icon: 'faHeadset',
            title: 'Suporte Sempre ao Seu Lado',
            /* description: 'Você irá possuir acesso direto à nossa equipe, pronta para ajudar em qualquer questão ou dúvida relacionada ao ERP. Com suporte ágil e eficiente, garantimos que sua empresa tenha o suporte necessário para manter o sistema sempre funcionando perfeitamente.' */
            description: 'Você terá acesso direto à nossa equipe de suporte, sempre pronta para auxiliá-lo em qualquer questão ou dúvida relacionada ao ERP. Com um atendimento ágil e eficiente, garantimos que sua empresa receba o suporte necessário para manter o sistema operando perfeitamente. Nossa equipe está comprometida em oferecer soluções rápidas e eficazes, assegurando que você possa focar no que realmente importa: o crescimento e o sucesso do seu negócio, enquanto nós cuidamos da estabilidade e do bom funcionamento do sistema.'
        },
        {
            icon: 'faCrosshairs',
            title: 'Foco no que Você Precisa',
            /* description: 'O Arezdot ERP é desenvolvido com base no que você precisa. Sempre focado em atender as necessidades dos clientes, o sistema é constantemente atualizado com base em feedbacks e identificação de uso. Isso garante que você tenha sempre as ferramentas certas para gerenciar sua empresa de forma eficiente.' */
            description: 'O Arezdot ERP é desenvolvido com foco total nas suas necessidades. Projetado para evoluir conforme o feedback dos clientes e a análise de uso, o sistema é constantemente atualizado para garantir que você tenha sempre as ferramentas mais adequadas para gerenciar sua empresa de maneira eficiente. Cada atualização é pensada para otimizar a experiência do usuário, trazendo soluções práticas e adaptadas à realidade do seu negócio, permitindo que você maximize sua produtividade e tome decisões estratégicas com mais confiança e precisão.'
        },
        {
            icon: 'faArrowUpRightDots',
            title: 'Constante Expansão',
            /* description: 'Nosso sistema está em constante expansão. Novas funcionalidades são adicionadas regularmente, garantindo que você tenha sempre as ferramentas mais avançadas para gerenciar sua empresa. Com atualizações frequentes, você sempre terá acesso às últimas inovações do mercado, sem nenhum custo adicional.' */
            description: 'Nosso sistema está em constante evolução, com novas funcionalidades sendo adicionadas regularmente. Isso garante que você tenha sempre acesso às ferramentas mais avançadas para gerenciar sua empresa de maneira eficiente e competitiva. Com atualizações frequentes, oferecemos as mais recentes inovações do mercado, sem nenhum custo adicional, permitindo que sua empresa esteja sempre à frente com tecnologia de ponta. Mantenha-se atualizado e aproveite o melhor que o mercado tem a oferecer, sem preocupações extras.'
        }
    ]);

    const plansPeriods = [
        {
            value: 0,
            label: 'Mensal',
            abbr: 'mês',
            planValue: 1
        },
        {
            value: 1,
            label: 'Semestral',
            abbr: 'semestre',
            planValue: 0.95
        },
        {
            value: 2,
            label: 'Anual',
            abbr: 'ano',
            planValue: 0.9
        }
    ]

    const getPlans = () => {
        axios.post('https://api.arezdot.com/public/plan/list', {
            page: 0,
            limit: 9999999999999
        }).then((response) => {
            if (response.status === 200) {
                setPlans(response.data.data.data.filter((plan: any) => plan.showOnWebsite === true).sort((a: any, b: any) => a.value - b.value));
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getPlans();
    }, []);

    const changePlan = (direction: 'next' | 'back') => {
        if (direction === 'next') {
            if (planShowing < (plans.length - 1)) {
                setPlanShowing(planShowing + 1);
            } else {
                setPlanShowing(planShowing);
            }
        } else {
            if (planShowing >= 1) {
                setPlanShowing(planShowing - 1);
            } else {
                setPlanShowing(planShowing);
            }
        }
    }

    return {
        plans,
        general,
        planPeriod,
        planShowing,
        plansPeriods,
        functionalities,
        setPlans,
        changePlan,
        setPlanPeriod,
        setPlanShowing
    }
}

export default useController;