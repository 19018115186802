import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
import { Toaster } from 'sonner';

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Toaster toastOptions={{
      style: {
        display: 'flex',
        height: '100px',
        width: '350px',
        margin: 'calc((100dvh - 150px)) calc((100vw - 350px) / 2)',
        flexDirection: 'row',
        justifyContent: 'center',
      }
    }} richColors closeButton/>
  </React.StrictMode>
);

reportWebVitals();
