import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';

const DefaultLoadingArezdot = (props: {active: boolean, showName?: false}) => {
    const controller = useController();

    return props.active && props.active == true ? (
        <S.main>
            <S.loadingContainer>
                <S.imgBit src={'/app/images/logo/logo_arezdot_glow_only.png'} className='logo_arezdot_glow'/>
                <S.imgBit src={'/app/images/logo/logo_arezdot_draw_only.png'} className='logo_arezdot_draw'/>
                {props.showName && props.showName == true ? <S.imgBit src={'/app/images/logo/logo_arezdot_name_only.png'} className='logo_arezdot_name'/> : null}
            </S.loadingContainer>
        </S.main>
    ) : null;
}

export default DefaultLoadingArezdot;