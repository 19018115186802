import styled from 'styled-components';

export const mainContainer = styled.div`
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    background: black;

    *{
        margin-top: -30px;
    }
`;

export const mainTitle = styled.h1`
    width: 100%;
    margin-top: 250px;
    text-align: center;
    color: #E6CF00;
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

    @media(max-width: 768px){
        width: 80%;
        margin-top: 180px;
        font-size: 35px;
    }
`;