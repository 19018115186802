import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const mainContainer = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 10%, rgba(87, 79, 3, 0.3) 100%);

    @media (prefers-color-scheme: dark) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 10%, rgba(87, 79, 3, 0.3) 100%);
    }

    @media (prefers-color-scheme: light) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 10%, rgba(87, 79, 3, 0.3) 100%);
    }

    > #tsparticles {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1; 
    }

    @media (max-width: 500px) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 10%, rgba(87, 79, 3, 0.3) 100%);
    }
`;

export const mainWithLogo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media (max-width: 500px) {
        justify-content: center;
        width: 100%;
    }
`;

const logoSize = {
    width: 300,
    height: 300,
}

export const imageLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: ${logoSize.width}px;
    height: ${logoSize.height}px;
    position: relative;
    
    @media (max-width: 1366px) {
        width: ${logoSize.width * 0.9}px;
        height: ${logoSize.height * 0.9}px;
    }

    @media (max-width: 1200px) {
        width: ${logoSize.width * 0.55}px;
        height: ${logoSize.height * 0.55}px;
    }

    @media (max-width: 768px) {
        width: ${logoSize.width * 0.8}px;
        height: ${logoSize.height * 0.8}px;
    }

    @media (max-width: 500px) {
        width: ${logoSize.width * 0.55}px;
        height: ${logoSize.height * 0.55}px;
    }
`;

export const imgLogoYellowPart = styled.img`
    position: absolute;
    z-index: 1;
    width: ${logoSize.width}px;
    height: ${logoSize.height}px;
    
    @media (max-width: 1366px) {
        width: ${logoSize.width * 0.9}px;
        height: ${logoSize.height * 0.9}px;
    }

    @media (max-width: 1200px) {
        width: ${logoSize.width * 0.55}px;
        height: ${logoSize.height * 0.55}px;
    }

    @media (max-width: 768px) {
        width: ${logoSize.width * 0.8}px;
        height: ${logoSize.height * 0.8}px;
    }

    @media (max-width: 500px) {
        width: ${logoSize.width * 0.55}px;
        height: ${logoSize.height * 0.55}px;
    }
`;

export const imgLogoDraw = styled.img`
    position: absolute;
    z-index: 2;
    width: ${logoSize.width}px;
    height: ${logoSize.height}px;
    
    @media (max-width: 1366px) {
        width: ${logoSize.width * 0.9}px;
        height: ${logoSize.height * 0.9}px;
    }

    @media (max-width: 1200px) {
        width: ${logoSize.width * 0.55}px;
        height: ${logoSize.height * 0.55}px;
    }

    @media (max-width: 768px) {
        width: ${logoSize.width * 0.8}px;
        height: ${logoSize.height * 0.8}px;
    }

    @media (max-width: 500px) {
        width: ${logoSize.width * 0.55}px;
        height: ${logoSize.height * 0.55}px;
    }
`;

export const titles = styled.div`
    display: flex;
    flex-direction: column;
`;

export const title = styled.h1`
    font-size: 3rem;
    color: rgb(221, 199, 1);
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-top: -15px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 20px rgba(0, 0, 0, 1);

    @media (max-width: 1366px) {
        font-size: 2.5rem;
    }

    @media (max-width: 1200px) {
        font-size: 2rem;
    }

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1.75rem;
    }
`;
export const servicesArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 40px);
    gap: 25px;
    padding: 50px 20px 0 20px;

    @media (max-width: 1366px){
        margin-bottom: 50px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    }
`;

export const serviceBit = styled.a<{expanded: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 40px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.3s;
    cursor: pointer;
    text-decoration: none;

    ${props => props.expanded && css`
        background: rgba(0, 0, 0, 0.8);
    `}

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 768px) {
        flex-direction: row;
        padding: 20px;
        gap: 20px;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

        
`;

export const serviceIcon = styled(FontAwesomeIcon)`
    font-size: 8rem;
    color: white;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 5rem;
        min-width: 120px;
    }

    @media (max-width: 500px) {
        min-width: 0px;
    }
`;

export const serviceText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media (max-width: 768px) {
        padding-left: 20px;
        border-left: 1px solid white;
    }

    @media (max-width: 500px) {
        padding-left: 0;
        border-left: 0;
    }
`;

export const serviceTitle = styled.h2`
    font-size: 1.75rem;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin: 25px 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1.25rem;
    }
`;

export const serviceDescription = styled.p`
    font-size: 1.15rem;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: justify;

    @media (max-width: 768px) {
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 0.9rem;
    }
`;