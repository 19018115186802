import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const popIn = keyframes`
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(0.90);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
`;

export const mainContainer = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 10%, rgba(0, 0, 0, 0.50) 100%);

    @media (prefers-color-scheme: dark) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 10%, rgba(0, 0, 0, 0.50) 100%);
    }

    @media (prefers-color-scheme: light) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 10%, rgba(0, 0, 0, 0.50) 100%);
    }
`;

export const header = styled.div`
    width: calc(100% - 20px);
    height: 50px;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    
    @media (max-width: 500px) {
        padding: 15px 10px;
}
`;

export const headerTitle = styled.h1`
    font-size: 2rem;
    color: #fff;
`;

export const buttonsContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 3px;
    }
`;

export const button = styled.button`
    width: 175px;
    height: 40px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    background: transparent;
    color: #fff;
    transition-duration: 0.3s;
    border: 1px solid #fff;

    &:hover {
        background: #fff;
        color: #000;
    }

    @media (max-width: 500px) {
        width: 100px;
        height: 25px;
        margin: 0px;
        text-align: end;
        border: none;
        font-size: 0.8rem;

        &:hover {
            background: transparent;
            color: #fff;
            text-decoration: underline;
        }
    }
`;

export const body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
    height: calc(100% - 50px);
    overflow-y: auto;
`;

export const mainWithLogo = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 50px 0 100px 0;
    gap: 175px;
    width: 80%;

    @media (max-width: 500px) {
        margin: 50px 0 50px 0;
        justify-content: center;
        gap: 70px;
        width: 90%;
    }
`;

export const imageLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 500px;
    height: 500px;
    position: relative;
    
    @media (max-width: 1366px) {
        width: 350px;
        height: 350px;
    }

    @media (max-width: 768px) {
        width: 250px;
        height: 250px;
    }

    @media (max-width: 500px) {
        width: 175px;
        height: 175px;
    }
`;

export const imgLogoYellowPart = styled.img`
    position: absolute;
    z-index: 1;
    width: 500px;
    height: 500px;

    @media (max-width: 1366px) {
        width: 350px;
        height: 350px;
    }

    @media (max-width: 768px) {
        width: 250px;
        height: 250px;
    }

    @media (max-width: 500px) {
        width: 175px;
        height: 175px;
    }
`;

export const imgLogoDraw = styled.img`
    position: absolute;
    z-index: 2;
    width: 500px;
    height: 500px;
    
    @media (max-width: 1366px) {
        width: 350px;
        height: 350px;
    }

    @media (max-width: 768px) {
        width: 250px;
        height: 250px;
    }

    @media (max-width: 500px) {
        width: 175px;
        height: 175px;
    }
`;

export const titles = styled.div`
    display: flex;
    flex-direction: column;
`;

export const title = styled.h1`
    font-size: 5rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 4rem;
    }

    @media (max-width: 768px) {
        font-size: 2rem;
    }

    @media (max-width: 500px) {
        font-size: 1.5rem;
    }
`;

export const subtitle = styled.h2`
    font-size: 1.55rem;
    color: #fff;
    width: 100%;

    @media (max-width: 1366px) {
        font-size: 1.25rem;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`;

export const functionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 90%;
    gap: 100px;
    margin-bottom: 50px;

    :nth-child(odd) {
        flex-direction: row;
    }

    :nth-child(even) {
        flex-direction: row-reverse;
    }

    @media (max-width: 768px) {
        gap: 50px;
        margin-top: 25px;
    }

    @media (max-width: 500px) {
        gap: 30px;
    }
`;

export const functionTexts = styled.div`
    display: flex;
    flex-direction: column !important;
    gap: 20px;
    width: 60%;

    @media (max-width: 500px) {
        width: 70%;
    }
`;

export const functionTitle = styled.h1`
    font-size: 1.75rem;
    color: #fff;
    border-bottom: 1px solid #fff;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }

    @media (max-width: 500px) {
        font-size: 1rem;
    }
`;

export const functionDescription = styled.p`
    font-size: 1rem;
    color: #fff;
    width: 100%;
    margin: 10px 0 40px 0;
    text-align: justify;

    @media (max-width: 768px) {
        font-size: 0.8rem;
    }

    @media (max-width: 500px) {
        font-size: 0.6rem;
    }
`;

export const functionBit = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

export const functionIcon = styled(FontAwesomeIcon)`
    font-size: 10rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 7rem;
    }

    @media (max-width: 500px) {
        font-size: 4rem;
    }
`;

export const contactButtonIcon = styled(FontAwesomeIcon)`
    font-size: 3.75rem;
    transition-duration: 0.1s !important;

    @media (max-width: 500px) {
        font-size: 3rem;
    }
`;

export const contactButtonText = styled.p`
    font-size: 1.75rem;
    margin-left: 10px;
    transition-duration: 0.1s;

    @media (max-width: 1200px) {
        font-size: 1.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1rem;
    }
`;

export const contactButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100px;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    margin: 50px 0 0 0;
    transition-duration: 0.3s;
    padding: 20px 10px;
    border: 1px solid white;
    gap: 30px;
    text-decoration: none;

    @media (max-width: 1200px) {
        margin: 0 0 50px 0;
        padding: 15px 10px;
        width: 80%;
        font-size: 0.9rem;
    }

    @media (max-width: 500px) {
        margin: 0 0 50px 0;
        padding: 10px 5px;
        font-size: 0.8rem;
        width: 70%;
        height: 75px;
    }
        
    &:hover {
        background: white;
        color: black;
        transform: scale(1.1);

        ${contactButtonIcon} {
            color: black;
            transition-duration: 0.1s !important;
        }
    }
`;

export const plansArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 50px 0 50px 0;
`;

export const plansTitle = styled.h1`
    font-size: 2.2rem;
    color: #fff;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1.25rem;
    }
`;

export const plansContainer = styled.div<{ planShowing: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg:first-child {
        opacity: ${props => props.planShowing === 0 ? 0 : 1};
    }
    
    svg:last-child {
        opacity: ${props => props.planShowing === 2 ? 0 : 1};
    }
`;

export const plansNextBackButton = styled(FontAwesomeIcon) <{ index: number }>`
    display: none;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition-duration: 0.3s;
    color: #fff;
    font-size: 2rem;
    margin: 0 60px;
    transition-duration: 0.1s;

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 500px) {
        margin: 0 15px;
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
    }
`;

export const plansBits = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 80%;

    @media (max-width: 1200px) {
        gap: 40px;
    }

    @media (max-width: 768px) {
        gap: 25px;
    }

    @media (max-width: 500px) {
        gap: 10px;
    }
`;

export const planBit = styled.div<{ planSelected: number, showPlan: boolean }>`
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 450px;
    background: linear-gradient(180deg, rgba(255, 255, 0, 0.20) 1%, rgba(0, 0, 0, 0.8) 100%);
    box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    padding: 25px 10px;
    gap: 10px;
    transition-duration: 0.3s;
    cursor: pointer;
    animation: ${popIn} 0.5s;

    &:hover {
        transform: scale(1.05);

        .hire_btn {
            background: #fff;
            color: #000;
        }
    }

    @media (max-width: 1200px) {
        height: 400px;
    }

    @media (max-width: 768px) {
        display: ${props => props.showPlan ? 'flex' : 'none'};
        width: 85%;
        height: 450px;
    }

    @media (max-width: 500px) {
        height: 400px;
        width: 95%;
    }
`;

export const planTitle = styled.h1`
    font-size: 1.25rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;

    @media (max-width: 1200px) {
        font-size: 1.1rem;
    }
`;

export const planItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    scroll-behavior: smooth;
    overflow-y: auto;
    padding: 10px;
`;

export const planItem = styled.p`
    font-size: 0.8rem;
    color: white;
    
    &::before {
        content: '»';
        margin-right: 5px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        font-size: 0.8rem;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`;

export const planValue = styled.p`
    font-size: 1.3rem;
    color: #00ff00;
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
    }
`;

export const planButton = styled.div`
    width: 80%;
    padding: 8px 0;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    align-self: center;
    text-align: center;
    border: 1px solid #fff;

    &:hover {
        background: #fff;
        color: #000;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }

    @media (max-width: 500px) {
        font-size: 0.9rem;
    }
`;

export const plansPeriods = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    margin: 15px 0 50px 0;

    :nth-child(2) {
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        padding: 0 50px;
    }

    @media (max-width: 768px) {
        gap: 25px;
    }

    @media (max-width: 500px) {
        gap: 10px;
        :nth-child(2) {
        padding: 0 10px;
    }
    }
`;

export const planPeriod = styled.p<{ selected: boolean }>`
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition-duration: 0.3s;
    text-transform: uppercase;
    text-align: center;


    ${props => props.selected ? css`
        text-decoration: underline;
        color: rgba(255, 255, 0, 0.75);
        opacity: 1;
    ` : css`
        opacity: 0.5;
    `}

    @media (max-width: 768px) {
        width: 33%;
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }

    &:hover {
        opacity: 1;
    }
`;
