import general from '../../utils/general';
import { useEffect, useState, useMemo } from 'react';
import { loadSlim } from "@tsparticles/slim";
import { initParticlesEngine } from "@tsparticles/react";
import {
    type Container,
    type ISourceOptions,
    MoveDirection,
    OutMode,
  } from "@tsparticles/engine";

export const useController = () => {
    const [services, setServices] = useState([
        {
            id: 0,
            title: 'Desenvolvimento e Programação',
            description: 'Serviços completos de programação e desenvolvimento para impulsionar a presença digital e automatizar processos. Inclui desenvolvimento de sites, aplicativos, scripts para automação e soluções personalizadas que simplificam tarefas e melhoram a eficiência operacional. Nosso foco está na criação de ferramentas seguras, eficientes e otimizadas para aprimorar a experiência do usuário e garantir resultados duradouros.',
            icon: 'faCode',
            expanded: false,
            sendTo: 'https://erp.arezdot.com/io/form/6KC6X2R66FLRU363dpmHyve6E',
        },
        {
            id: 1,
            title: 'ERP e Gestão Empresarial',
            description: 'Soluções ERP (Enterprise Resource Planning) que centralizam e integram todos os processos da sua empresa em uma única plataforma completa. Nossa ferramenta robusta facilita o controle financeiro, gestão de estoque, fluxo de caixa, acompanhamento de vendas e compras, criação de formulários, gerenciamento de fornecedores, clientes, funcionários, agenda e muito mais. Tudo isso para apoiar decisões estratégicas mais informadas e aprimorar a eficiência operacional.',
            icon: 'faBuilding',
            expanded: false,
            sendTo: 'https://www.arezdot.com/erp',
        },
        {
            id: 2,
            title: 'Design Digital',
            description: 'Serviço especializado na criação de logos e banners que refletem a identidade visual e os valores da sua marca. Desenvolvemos peças visuais impactantes e personalizadas para fortalecer sua presença digital e transmitir profissionalismo, seja em websites, redes sociais ou materiais promocionais. Nossa abordagem garante uma identidade visual coesa e memorável, ajudando a destacar sua empresa no mercado.',
            icon: 'faPalette',
            expanded: false,
            sendTo: 'https://erp.arezdot.com/io/form/Tev48bIOHwEJLxMqvYRkPSlRU',
        },
    ]);

    const [init, setInit] = useState(false);
    const particlesLoaded = (container?: Container) => {};

    const toggleService = (id: number) => {
        setServices(services.map((service) => {
            if (service.id === id) {
                service.expanded = !service.expanded;
            }
            return service;
        }));
    }

    useEffect(() => {
        initParticlesEngine(async (engine) => {
          await loadSlim(engine);
        }).then(() => {
          setInit(true);
        });
      }, []);

      const options: ISourceOptions = useMemo(
        () => ({
          background: {
            color: {
              value: "#574f03",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "grab",
              },
            },
            modes: {
              push: {
                quantity: 1,
              },
              grab: {
                distance: 200,
                quantity: 2,
                links: {
                  opacity: 1,
                },
              }
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 250,
              enable: true,
              opacity: 0.3,
              width: 1,
            },
            move: {
              direction: MoveDirection.none,
              enable: true,
              outModes: {
                default: OutMode.out,
              },
              random: true,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
              },
              value: 120,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 2 },
            },
          },
          detectRetina: true,
        }),
        [],
      );

    return {
        general,
        services,
        toggleService, 
        particlesLoaded, 
        options
    }
}

export default useController;